import { Asset } from './Asset';
import { Company } from './Companies';
import { Event, UxBeautyPrivateAttributes } from './Events';
import { AwardInProduct, Link, SegmentTag } from './global';
import {
  UsageLevelRange,
  RangeTo100,
  FoodIngredientsAssetsTypes
} from './productsHelpers';
import { CategoryDetail, Property, SearchCategoryType } from './Search';

export enum ProductType {
  FORMULATIONS = 'FORMULATIONS',
  INGREDIENTS = 'INGREDIENTS',
  SERVICES = 'SERVICES'
}

export interface FoodIngredient {
  categories: {
    commercial?: {
      /**MOQ: Minimum order quantity */
      moq?: { value: string; properties: { unit: string } };
      ['launch-year']?: { value: `${number}${number}${number}${number}` };
      packaging_information?: string[];
      preferred_invoicing?: string[];
      payment_terms?: string[];
    };
    ['regional-availability']?: {
      regions?: ValueProperties[];
      countries?: ValueProperties[];
    };
    technical?: FoodTechnicalCategories;
  };
  identifiers?: {
    trade_name: string[];

    general_name: string[];

    iupac: string[];

    cas: `${number}${number}-${number}${number}-${number}`[];

    ec: string[];

    /**
     * @example "123-456-7"
     * Expected format for FEMA number: XXX-XXX-X
     */

    fema: `${number}${number}${number}-${number}${number}${number}-${number}`[];

    flavis: string[];

    jecfa: string[];

    coe: string[];
    /**
     * @example "E309"
     * */
    e_number: `E${number}`[];
  };
  company_name: string;
  description: string;

  assets: FoodIngredientsAssetsTypes;
  segment: SegmentTag;
  type: ProductType;
  company: Partial<Company>;
  is_crawled: boolean;
  key: string;
  old_key: string;
  modified_at: string;
  created_at: string;
  completion_rate: number;
  webpage?: string;
}

export interface ProductFromSearch extends Product {
  old_key: string;
  event?: {
    event: {
      key: string;
      name: string;
    };
    product_private_attributes?: PrivateAttributes;
    display_name?: string;
    description?: string;
    promoted?: boolean;
    key: string;
  };
}

export interface Product {
  awards?: AwardInProduct[];
  key?: string;
  general_name?: string;
  product_event_key?: string;
  old_key?: string;
  score?: number;
  /**
   * @deprecated be careful here, this is often not defined
   */
  id?: string;
  category?: string;
  categories?: {
    general?: {
      samples: ValueProperties[];
      specialization: ValueProperties[];
    };
    ['use-storage']?: {
      howtouse: ValueProperties[];
      ['storage-requirements']: ValueProperties[];
    };
    ['formulation-procedure']?: {
      formulation: FormulationTableProperties[];
      procedure: FormulationProcedure[];
    };
    commercial?: ComercialCategories;
    technical?: TechnicalCategories;
    compliance?: ComplianceCategories;
    ['regional-availability']?: {
      regions: ValueProperties[];
      countries: ValueProperties[];
    };
    event?: {}; //TODO: specify event type we get from backend
  };
  assets?: Asset[];
  marketing?: ProductMarketing;
  company: Partial<Company>;
  webpage?: string;
  description?: string;
  identifiers?: {
    [identifier in ProductIdentifier]?: string[];
  } & {
    //formulation code
    code?: string;
  };
  links?: Link[];
  type: ProductType;
  name: string;
  segment: SegmentTag;
  completion_rate: number;
  modified_at: string; // "2022-07-27T14:09:55.722844Z"
  created_at: string; // "2022-07-27T14:09:55.722844Z"
  event?: any;
  is_crawled?: boolean; // TODO ???
  modified_by?: string;
  created_by?: string;
}

export enum ProductIdentifier {
  inci = 'inci',
  iupac = 'iupac',
  cas = 'cas',
  ec = 'ec',
  e = 'e',
  fema = 'fema',
  flavis = 'flavis',
  jecfa = 'jecfa',
  coe = 'coe',
  type = 'type'
}

export type FormulationProcedure = {
  value: string;
  id?: string;
  properties: {
    order: number;
  };
};

export type ValueProperties = {
  value: string;
  properties?: Property;
};
export type FormulationTableProperties = {
  value: string;
  id?: string;
  properties: {
    order: number;
    phase?: string;
    supplier?: string;
    weight?: string;
    phaseOrder?: number;
    inci?: string[];
    functions?: string[];
    linked_product_detail?: {
      old_key: string;
      name?: string;
      supplier_name?: string;
    };
  };
};

type ProductMarketing = {
  description: string;
  webpage: string;
};

export interface ComplianceCategories {
  hazardclassification?: { value: string; properties?: Property }[];
  hazardstatements?: { value: string }[];
}

export interface EventProduct extends Product {
  display_name: string;
  event: Partial<Event>;
  promotion?: { websites: Partial<Link>[] };
  status?: 'ENABLED' | 'DISABLED';
  promoted: boolean;
  properties?: any; //Wildcard property
  images: {
    reference: string;
    order: number;
    section: 'LOGO' | 'HEADER' | 'PRODUCT_MAIN' | 'PERSONALCARE' | 'HOMECARE';
  }[];
  enabled: boolean;
  bookmarked: boolean;
  rank: number;
  private_attributes?: PrivateAttributes;
  remark: string;
}
export type PrivateAttributes = {
  data?: {
    [type in UxBeautyPrivateAttributes]: string[];
  };
};

// covers both Product and EventProduct
export interface CommonProduct extends EventProduct {
  private_attributes: PrivateAttributes;
}

interface FoodTechnicalCategories {
  rci?: {
    value: RangeTo100;
  };
  functions?: {
    value: string;
    properties?: Property;
  }[];
  claims?: {
    value: string;
    properties?: Property;
  }[];
  usagelevel?: {
    value: string;
    properties?: Property;
  };
  properties?: {
    value: string;
    properties?: Property;
  }[];

  applications?: {
    value: string;
  }[];
  sustainability_claims: string[];
  performance_claims: string[];

  certifications?: { value: string; properties: { type: string } }[];
  /**
   * Category: Technical
   *
   * Range between 0 and 100% with 1 decimal house allowed.
   *
   * Example:0.5-2.0%
   * */

  usage_level: UsageLevelRange;
  chemical_group: string[];
  physical_characteristics: string[];
  chemical_characteristics: string[];
  appearance: string[];
  color: string[];
  flavor_family: string[];
  flavor: string;
  odor: string;
  origin?: CategoryDetail[];
  ['origin-species']?: {
    value: string;
    properties?: Property;
  }[];
  ['shelf-life']: string[]; //Food ingredients TODO: not sure it needs to be an array
  organoleptic?: { value: string; properties: { type: SearchCategoryType } }[];
}

export interface TechnicalCategories {
  rci?: {
    value: string;
  };
  functions?: {
    value: string;
    properties?: Property;
  }[];
  usagelevel?: {
    value: string;
    properties?: Property;
  };
  applications?: {
    value: string;
  }[];
  claims?: {
    value: string;
    properties?: Property;
  }[];
  certifications?: CategoryDetail[];

  chemicalgroup?: {
    value: string;
    properties?: Property;
  }[];
  properties?: {
    value: string;
    properties?: Property;
  }[];
  organoleptic?: {
    value: string;
    properties?: Property;
  }[];
  origin?: CategoryDetail[];
  ['origin-species']?: {
    value: string;
    properties?: Property;
  }[];
  trends?: {
    value: string;
    properties?: Property;
  }[];
  ['food-categories']?: {
    value: string;
    properties?: Property;
  }[];
  ['active-components']?: {
    value: string;
    properties?: Property;
  }[];
  ['shelf-life']?: {
    value: string;
    properties?: Property;
  }[];
}

interface ComercialCategories {
  moq?: {
    value: string;
    properties?: {
      unit?: string;
    };
  };
  ['launch-year']: { value: string };
  packaging?: CategoryValue;
  [x: string]: any;
}

interface CategoryValue {
  value: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Claim {
  value: string;
  properties: {
    type: string;
  };
}
