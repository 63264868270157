import {
  Box,
  Button,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { veryLightCoral } from 'theme/Theme';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Close from '@mui/icons-material/Close';

export const ProductsDropdownMenu: React.FC<{
  closeOuterMenu: () => void;
}> = ({ closeOuterMenu }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  //this is the breakpoint where the navigation bar changes from entries to a menu
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={isSmallScreen ? undefined : handlePopoverOpen}
        onClick={isSmallScreen ? handlePopoverOpen : undefined}
        endIcon={
          !isLargeScreen ? (
            <ChevronRight sx={{ ml: -1 }} />
          ) : (
            <ExpandMoreOutlinedIcon sx={{ ml: -1 }} />
          )
        }
        sx={theme => ({
          color: theme.palette.text.primary,
          fontWeight: 400,
          ml: {
            xs: 1,
            sm: undefined
          },
          fontSize: {
            xs: theme.typography.body1.fontSize,
            sm: 14
          }
        })}
      >
        Products
      </Button>
      {open &&
        (!isSmallScreen ? (
          <Menu
            id="mouse-over-popover"
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: isLargeScreen ? 'bottom' : 'center',
              horizontal: isLargeScreen ? 'left' : 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            onClick={handlePopoverClose}
            slotProps={{
              paper: {
                onMouseLeave: handlePopoverClose,
                sx: { borderRadius: 1, boxShadow: 2, maxWidth: '80%' }
              }
            }}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{
                padding: { xs: 1, sm: 3 },
                width: 'max-content'
              }}
            >
              {section.map(section => (
                <Stack
                  direction={{ xs: 'row', sm: 'column' }}
                  key={section.title}
                  sx={{
                    maxWidth: { xs: '50vw', md: '15vw' }
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      px: 2
                    }}
                  >
                    {section.title}
                  </Typography>
                  {section.elements.map(element => (
                    <Box
                      component={Link}
                      to={element.link}
                      key={element.title}
                      onClick={closeOuterMenu}
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        cursor: 'pointer',

                        [`&:hover,active`]: {
                          backgroundColor: veryLightCoral
                        }
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: ({ palette }) => palette.text.primary
                        }}
                      >
                        {element.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: ({ palette }) => palette.text.primary
                        }}
                      >
                        {element.description}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Menu>
        ) : (
          <Drawer
            open={open}
            onClose={handlePopoverClose}
            sx={{ zIndex: 10000 }}
            PaperProps={{ sx: { width: '70%' } }}
          >
            <Box
              key="title"
              sx={theme => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: `6px ${theme.spacing(1)} 6px ${theme.spacing(2)}`
              })}
            >
              <Typography
                variant="h4"
                sx={{
                  color: ({ palette }) => palette.text.primary,
                  pl: 1,
                  fontWeight: ({ typography }) => typography.h1.fontWeight
                }}
              >
                Products
              </Typography>
              <IconButton onClick={handlePopoverClose}>
                <Close />
              </IconButton>
            </Box>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              sx={{
                padding: { xs: 1, sm: 3 }
              }}
            >
              {section
                .map(section => section.elements)
                .flat()
                .map(section => (
                  <Stack
                    direction="column"
                    key={section.title}
                    sx={{
                      maxWidth: 300
                    }}
                  >
                    <MenuItem
                      key={section.title}
                      component={Link}
                      to={section.link}
                      onClick={() => {
                        handlePopoverClose();
                        closeOuterMenu();
                      }}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      {section.title}

                      <ArrowForwardIosIcon sx={{ ml: 1 }} />
                    </MenuItem>
                  </Stack>
                ))}
            </Stack>
          </Drawer>
        ))}
    </>
  );
};

const section = [
  {
    title: 'Covalo',
    elements: [
      {
        title: 'Covalo Data Platform',
        description:
          'Centrally manage all your data in one secure, integrated, AI-powered platform. ',
        link: '/dataplatform'
      }
    ]
  },
  {
    title: 'Supplier',
    elements: [
      {
        title: 'Supplier Marketplace',
        description: `Grow your business seamlessly on the world's largest ingredients platform.`,
        link: '/marketplace/suppliers'
      },
      {
        title: 'Customer Portal',
        description: `Showcase your product portfolio on your digital storefront, and customize with your own branding.`,
        link: '/customerportal'
      }
    ]
  },
  {
    title: 'Brand',
    elements: [
      {
        title: 'Brand Marketplace',
        description: `Discover the latest innovations on the world's largest ingredients platform.`,
        link: '/marketplace/brands'
      },
      {
        title: 'Brand Portal',
        description: `Unlock deeper insights and powerful tools to elevate your ingredient search and qualification.`,
        link: '/brandportal'
      }
    ]
  }
];
